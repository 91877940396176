<template>
    <div class="integration-box">
        <slot v-if="hasIntegration" />

        <div class="no-integration text-center" v-bind:class="{compact: compact}" v-else>
            <img src="/images/error/no-integration-illustration.png" class="" v-if="!compact">

            <h3 v-if="integrationText !== ''">{{integrationText}}</h3>
            <h3 v-else>{{$t('integrations.box.heading')}}</h3>
            <p class="text-muted">{{$t('integrations.box.startText')}} <router-link :to="{name: 'frontend.settings.integrations'}">{{$t('integrations.box.integrations')}}</router-link> {{$t('integrations.box.endText')}}</p>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.integration-box {
    .no-integration {
        padding: 20px 0 40px;

        &.compact {
            padding: 10px 14px;
         }

        img {
            width: 100px;
            margin: 30px 0 5px;
        }

        h3 {
            font-size: 16px;
        }

        p {
            font-size: 10pt;
        }

    }
}
</style>

<script>
import {mapGetters} from 'vuex'

export default {
    props: {
        integrations: {
            type: Array,
            required: true,
        },

        integrationText: String,

        compact: {
            type: Boolean,
            default: false
        },
    },

    computed: {
      ...mapGetters('identity', {
        identity: 'getIdentity'
      }),
      ...mapGetters('customer', {
        customer: 'getCustomer'
      }),

        text() {
            let text = ''
            const integrations = this.integrations

            if (typeof integrations !== 'undefined') {

                integrations.forEach((integration, index) => {
                    integration = integration.replace(/ review/, '')

                    if (integrations.length > 1 && index === (integrations.length - 1)) {
                        text += ' ' + $t('and') + ' '
                    }

                    if (integrations.length > 2 && index < integrations.length - 1 && index !== 0) {
                        text += ', '
                    }

                    text += integration
                })
            }

            return text
        },

        activeIntegrations() {
            if (!this.customer) {
                return []
            }

            return this.customer.integrations
        },

        services() {
            return this.activeIntegrations.map(function (integration) {
                return integration.service.name
            })
        },

        hasIntegration() {
            for (let i = 0; i < this.integrations.length; i++) {
                let integration = this.integrations[i]

                const index = this.services.indexOf(integration)

                if (index >= 0) {
                    return true
                }
            }

            return false
        }
    }
}
</script>
