const BaseService = require('@/services/BaseService')

const namespace = 'google-search-console'

const keywords = (options, cb, errorCb) => {
  BaseService.get(namespace + '/keywords', options, cb, errorCb)
}

const site = (options, cb, errorCb) => {
    BaseService.get(namespace + '/site', options, cb, errorCb)
}

export {
    keywords,
    site
}
