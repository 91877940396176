<template>
    <div class="top-ranks">
        <mobile-table>
            <div class="row">
                <div class="col-lg-6" style="padding: 0 5px;">
                    <div class="label-container">
                        <p v-html="$t('seo.topRanks.explainer', {topNumber: gscMaxKeywords})"></p>
                    </div>
                </div>
            </div>

            <div class="row table-header">
                <div class="col-lg-6 col-md-5 hidden-sm hidden-xs table-body__column">
                    <small class="text-muted">{{$t('seo.topRanks.tableHeader.keyword')}}</small>
                </div>

                <div class="col-lg-1 col-md-1 hidden-sm hidden-xs table-body__column">
                    <small class="text-muted header-text">{{$t('seo.topRanks.tableHeader.position')}}</small>
                </div>

                <div class="col-lg-2 col-md-1 hidden-sm hidden-xs table-body__column">
                    <small class="text-muted header-text">{{$t('seo.topRanks.tableHeader.impressions')}}</small>
                </div>

                <div class="col-lg-2 col-md-2 hidden-sm hidden-xs table-body__column">
                    <small class="text-muted header-text">{{$t('seo.topRanks.tableHeader.clicks')}}</small>
                </div>

                <div class="col-lg-1 col-md-2 hidden-sm hidden-xs table-body__column">
                    <small class="text-muted header-text">{{$t('seo.topRanks.tableHeader.ctr')}}</small>
                </div>
            </div>

            <template v-if="hasLoaded">
                <top-ranks-list-item :keyword="keyword" v-for="(keyword, i) in keywords" :key="i"/>
            </template>

            <div class="row" v-else-if="!errorMessage.length">
                <div class="col-lg-12 text-center" style="height: 120px; position: relative;">
                    <logo-loader :loadStage="loadStage"/>
                </div>
            </div>

            <div class="row" v-else>
                <div class="col-lg-12">
                    <span class="error-handler" v-html="errorMessage"/>
                </div>
            </div>
        </mobile-table>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';
    @import '~@/assets/scss/_custom.scss';

    .error-handler {
        max-width: 600px;
        display: inline-block;
        padding: 10px 20px;
        background: $primaryWarning;
        color: $tertiaryWarning;
        border-radius: 3px;
        margin-top: 10px;
    }

    .table-header {
        padding: 10px 20px;

        .table-body__column {
            border-bottom: 1px solid #f0f0f0;
            padding-bottom: 20px;
            min-height: 81px;
        }

        div:not(:last-child) {
            border-right: 1px solid #f0f0f0;
        }
    }

    .label-container {
        padding: 30px;
        margin: 10px 0;
        background: #f9f9f9;
        border-radius: 3px;
        min-height: 97px;

        p {
            margin: 0;
        }

        span {
            float: left;
            display: inline-block;
            margin-right: 20px;
            margin-bottom: 10px;
        }

        small {
            float: left;
            display: inline-block;
        }
    }

    @media screen and (max-width: 1400px) {
        small.header-text {
            position: absolute;
            top: 10px;
            left: 0;
            display: inline-block;
            transform-origin: 40% 60%;
            transform: rotate(70deg);
            padding: 0;
            margin: 0;
            display: block;
            max-width: 80px;
        }

        .table-header {
            .table-body__column {
                padding: 0;
                margin: 0;
                border-bottom: none;
            }

            div:not(:last-child) {
                border-right: none;
            }
        }
    }
</style>

<script>
  import MobileTable from '@/app/layout/components/MobileTable'
  import TopRanksListItem from '@/app/seo/components/TopRanksListItem'
  import LogoLoader from '@/app/shared/components/LogoLoader'
  import datepickerMixin from '@/app/datepicker/datepicker.mixin'

  const GoogleSearchConsoleService = require('@/services/google/GoogleSearchConsoleService')

  export default {
    mixins: [datepickerMixin],
    props: {
      gscMaxKeywords: {
        type: Number,
        required: true
      }
    },

    data () {
      return {
        keywords: [],
        hasLoaded: false,
        errorMessage: '',
        loadStage: 5
      }
    },

    mounted () {
      this.load()
    },

    methods: {
      loadKeywords () {
        this.keywords = []
        this.loadStage = 10

        GoogleSearchConsoleService.keywords({
          from: this.from.format('YYYY-MM-DD'),
          to: this.to.format('YYYY-MM-DD'),
          limit: this.gscMaxKeywords
        }, (response) => {
          const data = response.data
          const rows = data.rows

          this.keywords = rows.sort((a, b) => {
            const positionA = parseFloat(a.position)
            const positionB = parseFloat(b.position)

            const impressionsA = parseFloat(a.impressions)
            const impressionsB = parseFloat(b.impressions)

            const clicksA = parseFloat(a.clicks)
            const clicksB = parseFloat(b.clicks)

            if (positionA !== positionB) {
              return positionA - positionB
            }

            if (impressionsA !== impressionsB) {
              return impressionsB - impressionsA
            }

            return clicksB - clicksA
          })

          this.hasLoaded = true
        }, () => {
          this.errorMessage = $t('seo.topRanks.errors.data', { topNumber: this.gscMaxKeywords })
        })
      },

      load () {
        this.hasLoaded = false
        this.loadStage = 5

        GoogleSearchConsoleService.site({}, (response) => {
          const data = response.data

          if (data.permissionLevel === 'siteUnverifiedUser') {
            this.errorMessage = $t('seo.topRanks.errors.permission', {
              topNumber: this.gscMaxKeywords,
              url: data.siteUrl
            })

            return
          }

          this.loadKeywords()
        })
      }
    },

    components: {
      TopRanksListItem,
      MobileTable,
      LogoLoader
    }
  }
</script>
