'
<template>
	<div class="seo-keywords">
		<check-for-missing-integrations :integrations="['Seo']">
			<landing-page
				slot="no-integrations"
				:image="'/images/integrations/cta-messages/seo.png'"
				:headline="$t('seo.onboarding.headline')"
				:description="$t('seo.onboarding.description')">
				<hr />
				<p class="create-keyword-description" style="font-size: 16px">
					{{ $t("seo.onboarding.cta") }}
				</p>
				<div class="row">
					<div class="col-md-9">
						<create-keywords-input-basic />
					</div>
				</div>
			</landing-page>

			<div class="container-fluid">
				<!-- Missing Google Search Console integration -->
				<integration-teaser
					integration="Google Search Console"
					:description="$t('seo.integrationTeaser.googleSearchConsole.description')"
					:buttonText="$t('seo.integrationTeaser.googleSearchConsole.button')"
				/>

				<div class="row">
					<div class="col-lg-12">
						<template
							v-if="hasHealthyIntegration('Google analytics')">
							<!-- show if has google analytics -->
							<div class="row flex--parent">
								<div
									class="flex--child"
									:class="[!isFreemium
											? 'col-lg-6 col-md-12 col-sm-12 col-xs-12'
											: 'col-lg-4 col-md-6 col-sm-12 col-xs-12',]">
									<card>
										<no-integrations-box
											:integrations="['Google analytics']"
											:compact="true">
											<info-box
												icon="pe-7s-users"
												:from="from"
												:to="to"
												:number="visits"
												:unit="$t('seo.visitsFromSeo')">
											</info-box>
										</no-integrations-box>
									</card>
								</div>

								<div
									class="flex--child"
									:class="[!isFreemium
											? 'col-lg-6 col-md-12 col-sm-12 col-xs-12'
											: 'col-lg-4 col-md-6 col-sm-12 col-xs-12',]">
									<card>
										<info-box
											icon="pe-7s-graph1"
											:number="pageOneKeywords"
											:unit="$t('seo.keywordsOnFirstPage')">
										</info-box>
									</card>
								</div>
							</div>
						</template>

						<div class="clearfix"></div>

						<div class="col-custom-row">
							<div class="col-custom-12">
								<card
									class="tour-step-2"
									:headline="$t('seo.createKeywords.actionButton')"
									:description="$t('seo.createKeywords.headline')"
									icon="pe-7s-browser">
									<create-keywords :isFetching="keywordsIsLoaded" />
								</card>
							</div>
						</div>

						<div class="row">
							<div class="col-lg-12">
								<tab-navigation>
									<ul>
										<li>
											<a
												@click="navigate('keywords')"
												:style="organizationColor('keywords')">
												{{ $t("seo.tableKeywords") }}
											</a>
										</li>

										<li
											v-if="hasIntegration('Google Search Console')">
											<a @click="navigate('topranks')" :style="organizationColor('topranks')">
												{{ $t("seo.tableTopKeywords") }}
											</a>
										</li>
										<li
											v-else
											class="inactive text-center"
											:title="$t('seo.topRanks.missingIntegration')">
											<a>
												<i class="fa fa-info-circle" />
												{{ $t("seo.tableTopKeywords") }}
											</a>
										</li>
									</ul>

									<div class="clearfix"></div>

									<hr />

									<keep-alive>
										<keywords
											v-if="page === 'keywords'"
											ref="keywordList"
											@loaded="setKeywords()"
											@keywordsMounted="startTourWhenReady()"
											v-on:current-rankings-changed="currentRankingsChanged"
										/>
										<top-ranks
											v-if="page === 'topranks'"
											:gscMaxKeywords="gscMaxKeywords"
										/>
									</keep-alive>
								</tab-navigation>
							</div>
						</div>
					</div>
				</div>
			</div>
		</check-for-missing-integrations>
	</div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/_vars.scss";

.inactive {
	a {
		cursor: not-allowed !important;
		color: #e6e6e6 !important;
	}
}

.create-keyword-description {
	color: #888;
	margin-bottom: 15px;
}

.outage-notice {
	padding: 25px;
	font-size: 16px;
}

.flex--parent {
	display: flex;
}

.flex--child {
	display: flex;
	flex: 1;
}

@media screen and (max-width: 1200px) {
	.flex--parent {
		display: block;
	}

	.flex--child {
		display: block;
	}
}
</style>

<script>
import CheckForMissingIntegrations from "@/app/integrations/components/CheckForMissingIntegrations";
import CreateKeywordsInputBasic from "@/app/seo/components/CreateKeywordsInputBasic";
import NoIntegrationsBox from "@/app/integrations/components/NoIntegrationsBox";
import IntegrationTeaser from "@/app/integrations/components/IntegrationTeaser";
import CreateKeywords from "@/app/seo/components/CreateKeywords";
import NoProductPage from "@/app/integrations/components/NoProductPage";
import TabNavigation from "@/app/layout/components/TabNavigation";
import LandingPage from "@/app/onboarding/components/LandingPage";
import TopRanks from "@/app/seo/components/TopRanks";
import Keywords from "@/app/seo/components/Keywords";
import InfoBox from "@/app/layout/components/InfoBox";
import {getTranslatedErrorMessage} from "@/services/response/ResponseService";

import hasIntegration from "@/mixins/integrations/hasIntegration";
import organization from "@/mixins/customer/organization";
import tourMixin from "@/mixins/tours/tourMixin";

import { mapActions } from "vuex";

import AnalyticsService from "@/services/_app/google-analytics/AnalyticsService";
import datepickerMixin from "@/app/datepicker/datepicker.mixin";

export default {
	mixins: [hasIntegration, tourMixin, organization, datepickerMixin],

	data() {
		return {
			visible: ["organic"],
			currentRankings: [],
			visits: null,
			keywordsIsLoaded: false,
			page: "keywords",
			gscMaxKeywords: 50,
		};
	},

	computed: {
		pageOneKeywords() {
			if (!this.currentRankings.length) {
				return null;
			}

			return this.currentRankings.filter(function (rank) {
				return rank <= 10 && rank !== null;
			}).length;
		},

		keywords() {
			const $refs = this.$refs;

			if (!$refs.keywordList || !$refs.keywordList.sortedKeywords) {
				return [];
			}

			return $refs.keywordList.sortedKeywords;
		},
	},

	mounted() {
		this.load();
		eventHub.$on("keyword.created", this.afterKeywordCreated);
	},

	destroyed() {
		eventHub.$off("keyword.created", this.afterKeywordCreated);
	},

	methods: {
		...mapActions("identity", {
			reloadIdentity: "loadIdentity",
		}),

		organizationColor(trigger) {
			return trigger === this.page ? { borderBottom: `2px solid ${$org("colors.standard.secondary.hex")}`, } : null;
		},

		setVisible(visible) {
			this.visible = visible;
		},

		navigate(page) {
			this.page = page;
		},

		currentRankingsChanged(keywords) {
			this.currentRankings = Object.values(keywords)
				.filter((keyword) => {
					return keyword.currentRank !== undefined;
				})
				.map((keyword) => {
					return keyword.currentRank;
				});
		},

		setKeywords() {
			this.keywordsIsLoaded = true;
		},

		async load() {
			if (this.hasHealthyIntegration("Google analytics")) {
				this.visits = null;

				try {
					let body = await AnalyticsService.sessionsWithSources(
						this.from,
						this.to,
						null
					);

					if (body?.success === false) {
						this.$swal({
							type: 'error',
							text: getTranslatedErrorMessage(body.errorCode)
						});
						body = body?.errorMsg;
					}
					const rows = body.rows;

					for (let i = 0; i < rows.length; i++) {
						const row = rows[i];

						if (row.sourceMedium === "organic") {
							this.visits = row.sessions;
							break;
						}
					}
				} catch (e) {
					this.visits = 0;
				}
			}
		},

		afterKeywordCreated() {
			this.reloadIdentity();
		},

		startTourWhenReady() {
			let options = {
				steps: [
					{
						element: ".tour-step-1",
						intro: "<h5>Se dine placering på Google</h5><p>Herunder kan du se, hvordan din hjemmeside placerer sig på Google i henhold til dine udvalgte søgeord. Placeringerne opdateres hver uge, så du kan følge din udvikling.</p>",
						position: "top",
					},
					{
						element: ".tour-step-2",
						intro: "<h5>Vælg op til 10 søgeord</h5><p>Du kan følge op til 10 valgfrie søgeord. Se om dine Google-placeringer går frem eller tilbage - hver uge</p>",
					},
				],
			};

			if (this.isOnboarding) {
				options.steps.push({
					element: ".tour-step-integration",
					intro: "<h5>Fortsæt det gode arbejde</h5><p>Du har nu opsat din første integration i OP'N. Klik på integrationssiden for at opsætte den næste.</p>",
				});
			}

			this.startTour("seo", options);
		},
	},

	components: {
		InfoBox,
		Keywords,
		NoIntegrationsBox,
		TopRanks,
		NoProductPage,
		CreateKeywords,
		CreateKeywordsInputBasic,
		CheckForMissingIntegrations,
		TabNavigation,
		IntegrationTeaser,
		LandingPage,
	},
};
</script>
