<template>
    <div class="create-keywords-input" :class="{'large': large}">
        <transition-group name="slide-fade">
          <div class="alert alert-danger" v-for="(error, index) in errors" :key="index+1">
            <span><b> {{error[0] | capitalize}} </b></span>
          </div>
        </transition-group>

        <single-input-form
                @create="create($event)"
                @clearError="clearErrors"
                :isLoading="isLoading"
                :setValue="setValue"
                :errors="errors"
                :buttonText="$t('seo.createKeywords.actionButton')"
                :inputPlaceholder="$t('seo.createKeywords.inputField')" />
    </div>
</template>

<style lang="scss" scoped>
  .create-keywords-input.large {
    input {
      font-size: 16px;
      height: 50px;
      padding-left: 20px;
    }
    .btn {
      font-size: 16px;
      height: 50px;
    }
    .clear {
      top: 2px;
    }
  }
</style>

<script>
    import SingleInputForm from '@/app/seo/components/SingleInputForm'
    const KeywordService = require('@/services/seo/KeywordService')

    export default {
        props: {
            large: {
                type: Boolean,
                default: false,
            }
        },

        data () {
            return {
                isLoading: false,
                errors: [],
                setValue: null
            }
        },

        methods: {
            clearErrors () {
                this.errors = []
            },

            create(keyword) {
                this.isLoading = true
                this.clearErrors()

                KeywordService.create({keyword}, (response) => {
                    this.isLoading = false
                    this.setValue = ''

                    eventHub.$emit('keyword.created', response.data)
                }, (error) => {
                    this.isLoading = false

                    if (error.response && error.response.status === 422) {
                        this.errors = error.response.data.errors[0].title
                    }
                })
            },
        },
        components: {
            SingleInputForm
        },
    }
</script>
