<template>
    <div class="row">
        <div class="col-md-12">
            <div class="row form-content" v-if="hasLoaded">
                <div class="col-lg-7">
                    <create-keywords-form :keywords="keywords"
                                          @selectedKeywords="setSelectedKeywords"
                                          :limit="limit"
                    />

                </div>

                <div class="col-lg-5">
                    <p class="text-muted">{{$t('seo.createKeywords.explanation')}}</p>

                    <p class="text-muted"
                       v-if="remainingKeywords >= 1"
                       v-html="$t('seo.createKeywords.moreLeft', {remaining: remainingKeywords, limit: limit})"
                    ></p>

                    <p v-else v-html="$t('seo.createKeywords.noneLeft')"></p>
                </div>
            </div>

            <div class="row" v-else>
                <div class="col-lg-12 text-center" style="height: 100px; position: relative;">
                    <logo-loader />
                </div>
            </div>

            <div class="row" v-if="remainingKeywords > 0">
                <hr>

                <div class="col-md-12" v-if="suggestions.length">
                    <div class="suggestions">
                        <span class="suggestions-headline">{{$t('seo.createKeywords.suggestedKeywords')}}</span><br><br>

                        <template v-for="suggestion in filteredSuggestions">
                            <span class="label label-default" :class="{adding: addingKeyword === suggestion}" @click="addKeyword(suggestion)">
                                <span class="fa fa-circle-o-notch fa-spin fa-fw loader" />
                                {{suggestion.keyword}}
                            </span>&nbsp;
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .search-words {
        color:#888888;
        padding:0 5px 5px 0;
    }

    .suggestions {
        padding-top: 15px;
        padding-left: 4px;
        .suggestions-headline {
            font-family: 'Roboto', 'Helvetica Neue', sans-serif;
            color: #333;
        }
    }

    .label {
        position: relative;
        background: #f9f9f9;
        color: #a0a0a0;
        font-size: 12px;
        padding: 8px 12px;
        margin-bottom: 7px;
        display: inline-block;
        cursor: pointer;

        &:hover {
            background: #f0f0f0;
        }

        .loader {
            display: none;
        }

        &.adding {
            &:after {
                position: absolute;
                width: 100%;
                height: 100%;
                content: ' ';
                left: 0;
                top: 0;
                background-color: rgba(255, 255, 255, 0.9);
                z-index: 2;
                border-radius: .25em;
                border: 1px solid #f9f9f9;
            }

            .loader {
                color: #000;
                display: block;
                position: absolute;
                left: calc(50% - 10px);
                top: 18px;
                z-index: 3;
            }
        }
    }

    @media screen and (max-width: 1200px) {
        .form-content {
            margin-bottom: 20px;
        }
    }
</style>

<script>

import CreateKeywordsForm from '@/app/seo/components/CreateKeywordsForm'

import * as KeywordService from '@/services/seo/KeywordService'
import SuggestionService from '@/services/seo/SuggestionService'
import MixpanelService from '@/services/mixpanel/MixpanelService'

import organization from '@/mixins/customer/organization'
import feature from '@/mixins/billing/feature'
import LogoLoader from "@/app/shared/components/LogoLoader";
import {mapGetters} from "vuex";

export default {
    mixins: [organization, feature],

    props: {
        isFetching: Boolean
    },

    data() {
        return {
            keywords: [],
            suggestions: [],
            addingKeyword: null,
            isSaving: false,
            selectedKeywords: [],
            hasLoaded: false
        }
    },

    computed: {
		...mapGetters('customer', {
			customer: 'getCustomer',
		}),

        limit() {
			const keywords = this.featuresMax('seo.keywords')

            return !! keywords ? keywords : 0
        },

        filteredSuggestions() {
            const keywords = this.keywords.map(keyword => {
                return keyword.keyword.toLowerCase()
            })

            return this.suggestions.filter(suggestion => {
                return !keywords.includes(suggestion.keyword.toLowerCase())
            })
        },

        userAddedKeywords() {
            return this.keywords.filter(keyword => {
                return keyword.tags.includes('usertag')
            })
        },

        remainingKeywords() {
            const userAddedKeywords = this.userAddedKeywords

            return this.limit - userAddedKeywords.length - this.selectedKeywords.length
        },
    },

    mounted() {
        this.load()
        this.loadSuggestions()

        eventHub.$on('keyword.created', this.load)
        eventHub.$on('keyword.removed', this.load)
    },

    destroyed() {
        eventHub.$off('keyword.created', this.load)
        eventHub.$off('keyword.removed', this.load)
    },

    methods: {
        load() {
			const options = {
				filter_groups: []
			}

			if (this.customer) {
				options.filter_groups.push({
					filters: [{
						key: 'customer',
						operator: 'eq',
						value: this.customer.id
					}]
				})
			}

            KeywordService.findAll(options, response => {
                const data = response.data

                this.keywords = data.rows
                this.hasLoaded = true
            })
        },

        loadSuggestions() {
			const options = {
				filter_groups: [
					{
						filters: [
							{
								key: 'numberOfResults',
								operator: 'gt',
								value: 500
							},
							{
								key: 'numberOfResults',
								operator: 'lt',
								value: 1500000
							}
						]
					}
				]
			}

			if (this.customer) {
				options.filter_groups.push({
					filters: [{
						key: 'customer',
						operator: 'eq',
						value: this.customer.id
					}]
				})
			}

            SuggestionService.findAll(options, response => {
                const data = response.data
                this.suggestions = data.rows
            })
        },

        addKeyword(suggestion) {
            this.addingKeyword = suggestion

            const keyword = suggestion.keyword

            KeywordService.create({keyword: keyword}, response => {
                this.addingKeyword = null

                this.suggestions.splice(this.suggestions.indexOf(suggestion), 1)

                MixpanelService.track('SEO - Keyword - Create - Suggestion', {keyword: keyword})

                eventHub.$emit('keyword.created', response.data)
            }, error => {
                this.addingKeyword = null

                if (error.response && error.response.status === 422) {
                    this.errors = error.response.data.errors[0].title
                }
            })
        },

        setSelectedKeywords(selectedKeywords) {
            this.selectedKeywords = selectedKeywords
        }
    },

    components: {
        LogoLoader,
        CreateKeywordsForm
    },
}
</script>
