<template>
    <div class="product-box">
        <slot v-if="hasProduct"></slot>

        <div class="no-product text-center" v-else>
            <img src="/images/error/no-integration-illustration.png" class="">

            <h3>{{$t('integrations.noProductPage.heading', {product: $t('products.names.' + product)})}}</h3>
            <p class="text-muted">{{$t('integrations.noProductPage.text', {phoneNumber: $t('phoneNumber'), product: $t('products.names.' + product)})}}</p>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.product-box {
    .no-product {
        position: absolute;
        z-index: 10;
        left: 0;
        top: 2px;
        right: 0;
        bottom: 0px;
        background: rgba(245, 245, 245, 0.94);
        padding: 60px 0 40px;

        img {
            width: 200px;
            margin: 30px 0 5px;
        }
        h3 {
            font-size: 24px;
        }
        p {
            font-size: 16px;
            strong {
                font-weight: 600;
                color: #FFA534;
            }
        }
    }
}
</style>

<script>
import {mapGetters} from 'vuex'

export default {
    props: {
        product: String,
        needToBeActive: {
          type: Boolean,
          default: true,
        }
    },

    computed: {
      ...mapGetters('identity', {
        identity: 'getIdentity'
      }),
      ...mapGetters('customer', {
        customer: 'getCustomer'
      }),

        products() {
            if (!this.identity) {
                return null;
            }

            return this.customer.products;
        },

        hasProduct() {
            for (let product of this.products) {

              if (this.product == product.discr) {

                if (this.needToBeActive && !product.active) {
                  return false;
                }

                return true;
              }

            }

            return false;
        }
    },
    mounted() {
      if (!this.hasProduct) {
        eventHub.$emit('has-not-product');
      }
    }
}
</script>
