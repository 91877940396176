<template>
  <div class="integration-teaser">
    <check-for-missing-integrations :integrations="[integration]">
      <div class="row integration-container" slot="no-integrations">
        <div class="col-lg-12">
          <card :padding="false">
            <div class="row">
              <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 left-column">
                <h5><img :src="integrationLogoPath">
                  <template v-if="integrationText">{{integrationText}}</template>
                  <template v-else>{{integration}}</template>
                </h5>
                <p>
                  {{description}}
                </p>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 right-column text-center">
                <router-link :to="{name: 'frontend.settings.integrations'}">
                  <button class="btn-spoton" :style="organizationBackgroundColor">
                    {{buttonText}}
                  </button>
                </router-link>
              </div>
            </div>
          </card>
        </div>
      </div>
    </check-for-missing-integrations>
  </div>
</template>

<style lang="scss" scoped>
  .integration-container {
    .row {
      position: relative;
      .left-column {
        padding: 10px 60px 5px 30px;
        h5 {
          font-weight: 500;
          img {
            width: 30px;
            margin-top: -10px;
            margin-right: 5px;
          }
        }
        p {
          margin-left: 41px;
        }
      }
      .right-column {
        border-left: 1px solid #DDDDDD;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        background: #f9f9f9;
        padding: 30px 15px;
        margin-top: -15px;
        margin-bottom: -15px;
        border-radius: 0 3px 3px 0;
        .btn-spoton {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: 10px 20px;
          border-radius: 2px;
          border: none;
          background: #4091ff;
          color: #fff;
          box-shadow: 2px 2px 5px rgba(100, 100, 100, 0.3);
          &:hover {
            box-shadow: 1px 1px 3px rgba(50, 50, 50, 0.4);
          }
        }
      }
    }
  }

  @media screen and (max-width: 992px) {
    .integration-container {
      .row {
        .left-column {
          padding: 10px 15px 5px 30px;
          h5 {
            margin-bottom: 20px;
          }
          p {
            margin-left: 0;
          }

        }
        .right-column {
          position: relative;
          margin-top: 15px;
          border-left: none;
          border-top: 1px solid #DDDDDD;
          border-radius: 0 0 3px 3px;
        }
      }
    }
  }
</style>

<script>
    import Card from '@/app/shared/components/Card'

    import CheckForMissingIntegrations from '@/app/integrations/components/CheckForMissingIntegrations'
    import hasIntegration from '@/mixins/integrations/hasIntegration'

    export default {
        props: {
            integration: {
                type: String,
                required: true
            },
            integrationText: {
                type: String,
                required: false
            },
            buttonText: {
                type: String,
                default: 'Se mere'
            },
            description: {
                type: String,
                required: true
            }
        },

        computed: {
            integrationLogoPath() {
                const path = '/images/'

                switch(this.integration) {
                    case 'Google Search Console':
                        return path + 'google/google-search-console.svg'
                    case 'Google analytics':
                        return path + 'onboarding/google-analytics.svg'
                    case 'Freespee':
                        return path + 'onboarding/calls.png'
                    default:
                        return
                }
            },

            organizationBackgroundColor() {
                return {backgroundColor:$org('colors.standard.secondary.hex')}
            }
        },

        components: {
            Card,
            hasIntegration,
            CheckForMissingIntegrations
        }
    }
</script>
