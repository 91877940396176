<template>
    <div class="keyword-list-item row table-body">
        <div class="col-lg-6 col-md-5 col-sm-12 col-xs-12 table-body__column table-body__column--space">
            <span class="column-text"><span class="column-text">{{keyword.keys[0]}}</span></span>
        </div>

        <div class="col-lg-1 col-md-1 col-sm-6 col-xs-6 table-body__column table-body__column--space">
            <small class="sm-header">{{$t('seo.topRanks.tableHeader.position')}}</small>
            <span class="column-text">{{Math.round(keyword.position)}}</span>
        </div>

        <div class="col-lg-2 col-md-1 col-sm-6 col-xs-6 table-body__column table-body__column--space">
            <small class="sm-header">{{$t('seo.topRanks.tableHeader.impressions')}}</small>
            <span class="column-text">{{keyword.impressions}}</span>
        </div>

        <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6 table-body__column table-body__column--space">
            <small class="sm-header">{{$t('seo.topRanks.tableHeader.clicks')}}</small>
            <span class="column-text">{{keyword.clicks}}</span>
        </div>

        <div class="col-lg-1 col-md-2 col-sm-6 col-xs-6 table-body__column table-body__column--space">
            <small class="sm-header">{{$t('seo.topRanks.tableHeader.ctr')}}</small>
            <span class="column-text">{{ctrToPercentage}}%</span>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '~@/assets/scss/_vars.scss';
@import '~@/assets/scss/_mixins.scss';
@import '~@/assets/scss/_custom.scss';

.table-body {
    padding: 10px 20px;
    overflow: hidden;
    position: relative;
    &:nth-child(even) {
        background: #fcfcfc;
    }
    .table-body__column {
        min-height: 42px;
        padding-right: 20px;
        &:not(.no-ellipsis) {
            @include ellipsis;
        }
        a {
            &:hover {
                color: #7A7A7A;
            }
        }
        &.table-body__column--space {
            padding-top: 10px;
        }
    }
}

.sm-header {
    font-size: 10px;
    display: none;
}

@media screen and (max-width: 991px) {
    .table-body {
        text-align: center;
        .table-body__column {
            min-height: 63px;
            padding: 5px;
            white-space: normal;
        }
    }

    .sm-header {
        display: block;
    }
}
</style>

<script>
    export default {
        props: {
            keyword: Object
        },

        computed: {
            ctrToPercentage() {
                if (!this.keyword) {
                    return 0
                }

                const percentage = this.keyword.ctr * 100
                return percentage !== 0 ? percentage.toFixed(2) : 0
            }
        }
    }
</script>
